import React, { FunctionComponent, useEffect } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from 'antd';
import { MailOutlined, LockOutlined, WarningFilled } from '@ant-design/icons';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Div, Page } from '../../../framework';
import { setFlyoutOpen, clearError } from '../../../store/app.slice';
import {
  setAuthUser,
  clearSettings,
  clearTalentProfile,
  clearAuthUser,
} from '../../../store/user.slice';
import { useAuthService } from '../../../service/auth.service';
import Menu from '../../../component/Menu/Menu';
import RequestError from '../../../component/RequestError/RequestError';
import { formFieldToLowerCase } from '../../../helper/form';
import { RootState } from '../../../store/store';
import Password from 'antd/lib/input/Password';
import './Login.scss';

const { Title, Paragraph, Text } = Typography;

const LoginPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const authService = useAuthService();
  const { error } = useSelector((state: RootState) => state.app);
  const { userType } = useParams<{ userType: 'talent' | 'hirer' }>();
  const { search } = useLocation();
  const [form] = Form.useForm();
  const shouldShowMessage = new URLSearchParams(search).get('showMessage');
  const hasLoginError = error['auth/login']?.code === 'UNAUTHORIZED_EXCEPTION';

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));

    return () => {
      dispatch(clearError({ requestKey: 'auth/login' }));
    };
  }, []);

  const onFinish = async (values: any) => {
    const { remember, ...restValues } = values;

    dispatch(clearSettings());
    dispatch(clearTalentProfile());
    dispatch(clearAuthUser());
    const data = await authService.login(restValues, userType);

    dispatch(setAuthUser({ ...data, type: userType, remember }));
  };

  return (
    <Page className='page-login'>
      <Helmet>
        <title>Login as talent | Heycast.me</title>
        <meta property='og:title' content='Login as talent | Heycast.me' />
        <meta
          name='description'
          content='Login as talent to Heycast.me - A job matching platform for acting talent'
        />
        <meta
          property='og:description'
          content='Login as talent to Heycast.me - A job matching platform for acting talent'
        />
        <meta
          property='og:image'
          content='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/confetti-thumbnail.jpg'
        />
      </Helmet>
      <Div className='mobile-view'>
        <Menu />
        <Form
          form={form}
          style={{ width: '100%' }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          {shouldShowMessage ? (
            <Alert
              message="Congratulations! You've successfully created an account. Please login and complete your profile creation."
              type='success'
            />
          ) : null}
          <Title level={2} style={{ marginTop: '16px', marginBottom: '0' }}>
            Welcome
          </Title>
          <Paragraph>Sign in to continue</Paragraph>
          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
              {
                type: 'email',
                message: 'This is not a valid email',
              },
            ]}
            {...(hasLoginError
              ? {
                  hasFeedback: true,
                  validateStatus: 'error',
                }
              : {})}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder='Email'
              type='email'
              onBlur={() => formFieldToLowerCase(form, 'email')}
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[{ required: true, message: 'Please input your Password!' }]}
            {...(hasLoginError
              ? {
                  hasFeedback: true,
                  validateStatus: 'error',
                }
              : {})}
          >
            <Password prefix={<LockOutlined />} placeholder='Password' />
          </Form.Item>
          <Form.Item style={{ justifyContent: 'space-between' }}>
            <Row>
              <Col flex='auto'>
                <Form.Item name='remember' valuePropName='checked' noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
              </Col>
              <Col flex='auto' style={{ textAlign: 'right' }}>
                <Link
                  className='login-form-forgot'
                  to='/auth/talent/forgot-password'
                >
                  Forgot password
                </Link>
              </Col>
            </Row>
          </Form.Item>

          {hasLoginError ? (
            <Div mb='m'>
              <Text type='danger'>
                <WarningFilled /> Oops.. Either the email or the password is
                incorrect
              </Text>
            </Div>
          ) : null}

          <Form.Item>
            {/* Submit button auto load block */}
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
              block
            >
              Log in
            </Button>
            <RequestError requestKey='auth/login' />
            <Div mv='xl'>
              Not registered yet?{' '}
              <Link to='/auth/talent/register'>Create a talent profile</Link>
            </Div>
          </Form.Item>
        </Form>
      </Div>
    </Page>
  );
};

export default LoginPage;
