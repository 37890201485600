import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Breadcrumb, Empty, Select } from 'antd';
import Skeleton from 'react-loading-skeleton';
import { MoreOutlined } from '@ant-design/icons';
import {
  differenceInMinutes,
  format as formatDateString,
  parseISO,
} from 'date-fns';
import { Div, Button } from '../../framework';
import { statusText } from '../../helper/constants';
import { Project, Role, Applicant, Activity } from '../../helper/type';
import ApplicantCard from '../../component/Card/ApplicantCard/ApplicantCard';
import SkeletonApplicantCard from '../../component/Skeleton/ApplicantCard';
import RoleActionDropdown from '../../component/Dropdown/RoleActionDropdown';
import { useJobService } from '../../service/job.service';
import { useResetScroll } from '../../helper/hook';
import {
  setRoles as setGlobalRoles,
  setApplicants as setGlobalApplicants,
  setActivitiesMultiple,
} from '../../store/hirer.slice';
import { useActivityService } from '../../service/activity.service';
import { RootState } from '../../store/store';
import { useApplicantStatus } from '../../hook/useApplicantStatus';

import './Applicants.scss';

const { Title, Paragraph, Text, Link: AntdLink } = Typography;
const { Option } = Select;

const memoryStore = new Map();

type ApplicantWithSortStatus = Applicant & { sortStatus?: string };

const ApplicantsPage = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const { mappedProjects, mappedRoles, mappedApplicants, mappedActivities } =
    useSelector((state: RootState) => state.hirer);

  const { projectId, roleId } = useParams<{
    projectId: string;
    roleId: string;
  }>();
  const isCached = mappedApplicants && mappedApplicants[roleId];
  const hasLoadOtherApplicants = isCached
    ? mappedApplicants[roleId].hasLoadOtherApplicants
    : false;
  const jobService = useJobService();
  const activityService = useActivityService();
  const { getApplicantStatus } = useApplicantStatus('hirer');

  const [project, setProject] = useState<Partial<Project>>({});
  const [role, setRole] = useState<Role>();
  const [applicants, setApplicants] = useState<Applicant[]>();
  const [initialRecommendations, setInitialRecommendations] =
    useState<Applicant[]>();
  const [filter, setFilter] = useState<string>('all');
  const [filteredApplicants, setFilteredApplicants] =
    useState<ApplicantWithSortStatus[]>();
  const { loadStack } = useSelector((state: RootState) => state.app);

  const isLoadingSubsequentApplicants = loadStack.includes(
    'job/applicant/list?isRecommended=false'
  );

  const applicantsWithSortStatus = useMemo(() => {
    return (applicants ?? []).map((appl) => ({
      ...appl,
      sortStatus:
        getApplicantStatus({ roleId, talentId: appl.talentId }) || 'none',
    }));
  }, [mappedActivities, applicants]);

  const savePos = useCallback(
    (
      id: string,
      data: {
        scrollTop: number;
        id: string;
      }
    ) => {
      memoryStore.set(id, data);
    },
    []
  );

  const getPos = useCallback(
    (
      id: string
    ):
      | {
          scrollTop: number;
          id: string;
        }
      | undefined => {
      return memoryStore.get(id);
    },
    []
  );

  useEffect(() => {
    (async () => {
      const fetchLatestRecommendApplicants = async () => {
        const data: Applicant[] = await jobService.getApplicantsByRole(
          roleId,
          true
        );
        await loadApplicantActivities();

        return data;
      };

      const triggerRefetch =
        mappedApplicants[roleId]?.lastFetchTimestamp &&
        differenceInMinutes(
          new Date(),
          new Date(mappedApplicants[roleId].lastFetchTimestamp)
        ) >= 10;

      if (!mappedApplicants[roleId]) {
        const data = await fetchLatestRecommendApplicants();

        dispatch(
          setGlobalApplicants({
            roleId,
            applicants: data,
            hasLoadOtherApplicants: false,
            lastFetchTimestamp: new Date().toISOString(),
          })
        );
      } else if (triggerRefetch) {
        const [latestRecom, latestOther] = await Promise.all([
          fetchLatestRecommendApplicants(),
          mappedApplicants[roleId].hasLoadOtherApplicants
            ? jobService.getApplicantsByRole(roleId, false)
            : ([] as Applicant[]),
        ]);

        dispatch(
          setGlobalApplicants({
            roleId,
            applicants: [...latestRecom, ...latestOther],
            hasLoadOtherApplicants:
              mappedApplicants[roleId].hasLoadOtherApplicants,
            lastFetchTimestamp: new Date().toISOString(),
          })
        );
      }
    })();
  }, []);

  useEffect(() => {
    if (mappedApplicants[roleId]) {
      setInitialRecommendations(
        mappedApplicants[roleId].applicants.filter(
          (applicant) => applicant.isRecommended === true
        )
      );
      setApplicants(mappedApplicants[roleId].applicants);
    }
  }, [mappedApplicants]);

  useEffect(() => {
    (async () => {
      if (mappedRoles[projectId]) {
        setRole(mappedRoles[projectId][roleId]);
      } else {
        const data = await jobService.getRolesByProject(projectId);
        dispatch(setGlobalRoles({ projectId, roles: data }));
      }
    })();
  }, [mappedRoles]);

  useEffect(() => {
    setProject(mappedProjects[projectId]);
  }, [mappedProjects]);

  useEffect(() => {
    if (!hasLoadOtherApplicants && initialRecommendations?.length === 0) {
      handleLoadOtherApplicants();
    }
  }, [initialRecommendations, hasLoadOtherApplicants]);

  useEffect(() => {
    setFilteredApplicants(
      filter === 'all'
        ? applicantsWithSortStatus?.filter(
            (applicant) => applicant.status !== 'withdrawn'
          )
        : applicantsWithSortStatus?.filter(
            (applicant) => filter === applicant.sortStatus
          )
    );
  }, [applicantsWithSortStatus, filter]);

  const loadApplicantActivities = async () => {
    let nextKey;
    const talentActivities: Record<string, Activity[]> = {};
    do {
      const result = await activityService.listRoleTalentActivity({
        roleId,
        isInterruptive: true,
      });
      nextKey = result.key;
      result.data.forEach((activity) => {
        if (!(activity.talentId in talentActivities))
          talentActivities[activity.talentId] = [];

        talentActivities[activity.talentId].push(activity);
      });
    } while (nextKey);

    dispatch(setActivitiesMultiple({ roleId, talentActivities }));
  };

  const sortApplicants = (
    a: ApplicantWithSortStatus,
    b: ApplicantWithSortStatus
  ) => {
    const sortOrder: Record<string, number> = {
      confirmationAccepted: 1,
      confirmationRejected: 2,
      pendingConfirmation: 3,
      castingScheduled: 4,
      castingInvited: 5,
      shortlistedConfirmed: 6,
      shortlistedRejected: 7,
      shortlisted: 8,
      withdrawn: 9,
      none: 10,
    };

    const sortRecommend = () => {
      if (a.isRecommended && !b.isRecommended) return -1;
      if (!a.isRecommended && b.isRecommended) return 1;
      return (b.recommendOrder ?? 0) - (a.recommendOrder ?? 0);
    };

    const sortStatusOrder =
      sortOrder[a.sortStatus || 'none'] - sortOrder[b.sortStatus || 'none'];
    if (sortStatusOrder !== 0) return sortStatusOrder;

    const recommendOrder = sortRecommend();
    if (recommendOrder !== 0) return recommendOrder;

    return b.appliedAt.localeCompare(a.appliedAt);
  };

  const renderInitialRecommendedApplicants = () => {
    if (isLoadingSubsequentApplicants || hasLoadOtherApplicants) {
      return <></>;
    }

    return (
      <SkeletonApplicantCard isRecommended={true}>
        {filteredApplicants?.length ? (
          [...filteredApplicants].sort(sortApplicants).map((applicant) => (
            <ApplicantCard
              ref={() => {
                if (getPos('restore')?.id === applicant.talentId) {
                  requestAnimationFrame(() => {
                    window.scrollTo({
                      top: getPos('restore')?.scrollTop,
                    });
                  });
                }
              }}
              key={applicant.talentId}
              applicant={applicant}
              role={role}
              project={project}
              handleClickCallback={() =>
                savePos('restore', {
                  scrollTop: window.scrollY,
                  id: applicant.talentId,
                })
              }
            />
          ))
        ) : (
          <Div className='zero-state'>
            <Empty description={renderEmptyDescription()} />
          </Div>
        )}
      </SkeletonApplicantCard>
    );
  };

  const renderSubsequentApplicants = () => {
    if (!hasLoadOtherApplicants && !isLoadingSubsequentApplicants) {
      return <></>;
    }

    return (
      <>
        <>
          {isLoadingSubsequentApplicants && initialRecommendations?.length
            ? [...initialRecommendations]
                .sort(sortApplicants)
                .map((applicant) => (
                  <ApplicantCard
                    ref={() => {
                      if (getPos('restore')?.id === applicant.talentId) {
                        requestAnimationFrame(() => {
                          window.scrollTo({
                            top: getPos('restore')?.scrollTop,
                          });
                        });
                      }
                    }}
                    key={applicant.talentId}
                    applicant={applicant}
                    role={role}
                    project={project}
                    handleClickCallback={() =>
                      savePos('restore', {
                        scrollTop: window.scrollY,
                        id: applicant.talentId,
                      })
                    }
                  />
                ))
            : null}
        </>

        <SkeletonApplicantCard isRecommended={false}>
          {filteredApplicants?.length ? (
            [...filteredApplicants].sort(sortApplicants).map((applicant) => (
              <ApplicantCard
                ref={() => {
                  if (getPos('restore')?.id === applicant.talentId) {
                    requestAnimationFrame(() => {
                      window.scrollTo({
                        top: getPos('restore')?.scrollTop,
                      });
                    });
                  }
                }}
                key={applicant.talentId}
                applicant={applicant}
                role={role}
                project={project}
                handleClickCallback={() =>
                  savePos('restore', {
                    scrollTop: window.scrollY,
                    id: applicant.talentId,
                  })
                }
              />
            ))
          ) : (
            <Div className='zero-state'>
              <Empty description={renderEmptyDescription()} />
            </Div>
          )}
        </SkeletonApplicantCard>
      </>
    );
  };

  const handleLoadOtherApplicants = async () => {
    const data = await jobService.getApplicantsByRole(roleId, false);
    const updatedData = applicants!.concat(data);
    dispatch(
      setGlobalApplicants({
        roleId,
        applicants: updatedData,
        hasLoadOtherApplicants: true,
        lastFetchTimestamp: new Date().toISOString(),
      })
    );
    setApplicants(updatedData);
  };

  const renderRoleDetails = () => {
    return role ? (
      <Div className='role'>
        <Div flex className='title-container'>
          <Title level={4}>
            {role.name} <small>{role.type}</small>
          </Title>
          <Div noGrow className='button-container'>
            <RoleActionDropdown
              role={role}
              project={project}
              allowSendInvitation
            >
              <Button className='more-action-btn'>
                <MoreOutlined />
              </Button>
            </RoleActionDropdown>
          </Div>
        </Div>
        <Paragraph className='description'>
          {role.description &&
            role.description.split('\n').map((d, index) => (
              <Paragraph
                key={`${index}${d}`}
                style={{ marginBottom: '0.25rem' }}
              >
                {d}
              </Paragraph>
            ))}
        </Paragraph>
        <Div className='role-details'>
          <Div flex className='attribute'>
            <Div className='label'>GENDER</Div>
            <Div className='value capitalize'>{role.gender}</Div>
          </Div>
          <Div flex className='attribute'>
            <Div className='label'>AGE RANGE</Div>
            <Div className='value'>
              {role.ageFrom} - {role.ageTo} <small>YEARS OLD</small>
            </Div>
          </Div>
          <Div flex className='attribute'>
            <Div className='label'>RACE/ETHNICITY</Div>
            <Div className='value capitalize'>
              {role.raceEthnicity && role.raceEthnicity.join(', ')}
              {!role.raceEthnicity &&
                Boolean(role.race) &&
                Boolean(role.ethnicity) &&
                [role.race, role.ethnicity].join(', ')}
            </Div>
          </Div>
          <Div flex className='attribute'>
            <Div className='label'>LANGUAGE</Div>
            <Div className='value capitalize'>
              {role.languages.length
                ? role.languages.join(', ')
                : 'Not required'}
            </Div>
          </Div>
          <Div flex className='attribute'>
            <Div className='label'>TALENTS NEEDED</Div>
            <Div className='value'>
              {role.countNeeded} <small>PERSON(S)</small>
            </Div>
          </Div>
          {role.shootDates && role.shootDates.length ? (
            <Div flex className='attribute'>
              <Div className='label date'>SHOOT DATES</Div>
              <Div className='value'>
                {role.shootDates.map((date) => (
                  <Div key={date}>
                    {formatDateString(parseISO(date), 'dd MMM yyy')}
                  </Div>
                ))}
              </Div>
            </Div>
          ) : null}
          {/* <Div flex className='attribute'>
            <Div className='label'>PAYMENT</Div>
            <Div className='value'>
              {role.payment ? (
                <>
                  <small>RM</small> {role.payment}
                </>
              ) : (
                'TBC'
              )}
            </Div>
          </Div> */}
        </Div>
      </Div>
    ) : (
      <Div className='role'>
        <Title level={4}>
          <Skeleton width={100} height={20} />
        </Title>
        <Paragraph className='description'>
          <Skeleton width={270} height={10} />
          <Skeleton width={240} height={10} />
        </Paragraph>
        <Div className='role-details'>
          <Div flex className='attribute'>
            <Div className='label'>
              <Skeleton width={70} height={10} />
            </Div>
            <Div className='value capitalize'>
              <Skeleton width={70} height={20} />
            </Div>
          </Div>
          <Div flex className='attribute'>
            <Div className='label'>
              <Skeleton width={100} height={10} />
            </Div>
            <Div className='value'>
              <Skeleton width={150} height={20} />
            </Div>
          </Div>
          <Div flex className='attribute'>
            <Div className='label'>
              <Skeleton width={90} height={10} />
            </Div>
            <Div className='value capitalize'>
              <Skeleton width={70} height={20} />
            </Div>
          </Div>
          <Div flex className='attribute'>
            <Div className='label'>
              <Skeleton width={40} height={10} />
            </Div>
            <Div className='value capitalize'>
              <Skeleton width={70} height={20} />
            </Div>
          </Div>
          <Div flex className='attribute'>
            <Div className='label'>
              <Skeleton width={120} height={10} />
            </Div>
            <Div className='value capitalize'>
              <Skeleton width={70} height={20} />
            </Div>
          </Div>
          <Div flex className='attribute'>
            <Div className='label'>
              <Skeleton width={90} height={10} />
            </Div>
            <Div className='value capitalize'>
              <Skeleton width={90} height={20} />
            </Div>
          </Div>
          <Div flex className='attribute'>
            <Div className='label'>
              <Skeleton width={110} height={10} />
            </Div>
            <Div className='value'>
              <Skeleton width={100} height={20} />
            </Div>
          </Div>
          <Div flex className='attribute'>
            <Div className='label'>
              <Skeleton width={80} height={10} />
            </Div>
            <Div className='value'>
              <Skeleton width={70} height={20} />
            </Div>
          </Div>
        </Div>
      </Div>
    );
  };

  const handlerFilterChange = (value: string) => {
    setFilter(value);
  };

  const renderFilter = () => {
    return applicants?.length ? (
      <Div className='select-filter'>
        <Text>Filter applicants by</Text>
        <Select
          className='selector'
          defaultValue='all'
          value={filter}
          onChange={handlerFilterChange}
        >
          <Option value='all'>All</Option>
          {Object.keys(statusText).map((status) => (
            <Option key={status} value={status}>
              {statusText[status]}
            </Option>
          ))}
        </Select>
      </Div>
    ) : null;
  };

  const renderEmptyDescription = () => {
    if (applicants?.length) {
      return 'No applicants with this status';
    }

    if (hasLoadOtherApplicants) {
      return 'No applicants yet';
    }

    return (
      <>
        No recommended talents
        <br />
        <AntdLink onClick={handleLoadOtherApplicants}>
          Show other applicants
        </AntdLink>
      </>
    );
  };

  return (
    <Div className='page-hirer-applicants'>
      <Helmet>
        <title>
          {`${role?.name || 'Role'}`} - {`${project?.title || 'Project'}`} -
          Heycast.me
        </title>
        <meta name='description' content='The right talent. The right part.' />
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to='/hirer/home'>All Projects</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {project ? (
            <Link to={`/hirer/project/${project.id}`}>{project.title}</Link>
          ) : (
            'Project'
          )}
        </Breadcrumb.Item>
        <Breadcrumb.Item>{role?.name || 'Role'}</Breadcrumb.Item>
      </Breadcrumb>
      {renderRoleDetails()}
      {renderFilter()}
      <Div className='applicant-list'>
        {renderInitialRecommendedApplicants()}
        {renderSubsequentApplicants()}
      </Div>
      {!hasLoadOtherApplicants && !isLoadingSubsequentApplicants ? (
        <Button block onClick={handleLoadOtherApplicants}>
          Load Other Applicants
        </Button>
      ) : null}
    </Div>
  );
};

export default ApplicantsPage;
